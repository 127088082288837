<template>
  <section class="q-pa-md">
    <Mural
      :breadcrumbs="breadcrumbs"
      route="knowledge"
      :query="{}"
      :count="`${numberParticipants} grupo(s)`"
    >
      <q-btn
        color="default-pink"
        rounded
        label="Adicionar Grupos"
        no-caps
        no-wrap
        v-if="!finishedCourse"
        class="no-shadow"
        :to="{
          name: 'add-groups',
          query: {
            isFinished: finishedCourse,
            title: titleCourse,
            courseId: courseId,
          },
        }"
      />
    </Mural>
    <div class="col-12 flex row q-pa-sm">
      <div class="form-input col-12 column">
        <q-table
          :columns="columnsTable"
          :rows="rows"
          row-key="id"
          :selection="!finishedCourse ? 'multiple' : 'none'"
          color="default-pink"
          v-model:selected="selected"
          class="no-shadow"
          :filter="filter"
        >
          <template v-slot:top-left>
            <q-btn
              unelevated
              rounded
              color="default-pink"
              label="Apagar"
              size="md"
              no-caps
              icon-right="delete"
              outline
              v-if="selected.length > 0 && !finishedCourse"
              @click="disabled(selected)"
            />
          </template>
          <template v-slot:top-right>
            <q-input borderless dense v-model="filter" placeholder="Pesquisar">
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>
        </q-table>
      </div>
    </div>
  </section>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";

/* vue */
import { ref, reactive, onMounted } from "vue";

/* Router */
import { useRouter, useRoute } from "vue-router";

/* Quasar */
import { useQuasar } from "quasar";

/* Services */
import TrailDataServices from "@/services/TrailDataServices";

export default {
  name: "GroupsTrail",
  components: {
    Mural,
  },
  setup() {
    /* Constants */
    const _route = new useRoute();
    const _router = new useRouter();
    const $q = new useQuasar();
    const _trailDataServices = new TrailDataServices();

    const columnsTable = ref([
      {
        name: "id",
        align: "left",
        label: "id",
        field: "id",
        sortable: false,
      },
      {
        name: "group_name",
        align: "left",
        label: "Grupo",
        field: "group_name",
        sortable: false,
      },
      {
        name: "students_quantity",
        align: "left",
        label: "Membros",
        field: "students_quantity",
        sortable: false,
      },
    ]);
    const rows = ref([]);

    /* Variables */
    let breadcrumbs = ref(["Grupos participantes"]);
    let numberParticipants = ref(0);
    let finishedCourse = ref(_route.query.isFinished == 1 ? true : false);
    let titleCourse = _route.query.title;
    let courseId = _route.query.courseId;
    let selected = ref([]);
    let filter = ref("");

    /* Fcuntions */
    function disabled(group) {
      let message =
        group.length > 1
          ? "Deseja remover os grupos selecionados?"
          : "Deseja remover o grupo selecionado?";
      $q.notify({
        message: message,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              /*     removeGroupsSelected(group); */
              removeGroups(group);
            },
          },
        ],
      });
    }

    async function removeGroups(groups) {
      const formData = new FormData();

      formData.append("Course_id", courseId);

      groups.forEach((group) => {
        const { id } = group;
        formData.append("Group_id", id);
      });

      await _trailDataServices
        .deleteGroupsInCourse(formData)
        .then((response) => {
          console.log(response.data);
          if (response.data) {
            rows.value = rows.value.filter((row) => !groups.includes(row));
            selected.value = [];
            numberParticipants.value = rows.value.length;
            _alertOf(`Turma modificada com sucesso`, "green-9");
          }
        })
        .catch((error) => {
          console.log(error);
          _alertOf(error.response.data.toString());
        });
    }

    async function getGroupsInTrail() {
      await _trailDataServices
        .getAllGroupsInTrail(courseId)
        .then((response) => {
          response.data.forEach((group) => {
            rows.value.push(group);
          });
          numberParticipants.value = response.data.length;
        })
        .catch((error) => {});
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    /* LifeCycles */
    onMounted(() => {
      getGroupsInTrail();
    });

    return {
      /* Variables */
      breadcrumbs,
      finishedCourse,
      titleCourse,
      courseId,
      columnsTable,
      selected,
      filter,
      rows,
      numberParticipants,
      /* Functions */
      disabled,
    };
  },
};
</script>

<style scoped></style>
